import { RouteObject, redirect } from "react-router-dom";
import ErrorPage from "../views/error/ErrorPage.tsx";
import { authRoutes } from "./authRoutes.tsx";
import { privateRoutes } from "./privateRoutes.tsx";
import { sxUiRoutes } from "./sxUiRoutes.tsx";
import { redirectAdapter } from "./routerConditions.tsx";
import HomePage from "../views/public/homePage/HomePage.tsx";
import SiteLayout from "../components/layouts/SiteLayout.tsx";
import { IS_DEV } from "../services/config.ts";
import ContactPage from "../views/contact/ContactPage.tsx";
import { promiseAllObject } from "../react-helpers/promise.ts";
import { userService } from "../services/user/userService.tsx";
import ProfileAdvicePage from "../views/help/ProfileAdvicePage.tsx";
import { authService } from "../services/auth/authService.tsx";
import App from "../views/App.tsx";
import ProfilesListPage from "../views/profilesList/ProfilesListPage.tsx";
import { getBookingProfilesRequest } from "../services/bookingProfile/bookingProfileApi.ts";
import { getAllConfigDataRequest } from "../services/data/dataApi.ts";
import {
  dataService,
  mapConfigDataToAutocompleteOptions,
} from "../services/data/dataService.tsx";
import { bookingProfileService } from "../services/bookingProfile/bookingProfileService.tsx";
import BookingProfilePage from "../views/solicitation/bookingProfile/BookingProfilePage.tsx";
import CampaignPage from "../views/public/campaign/CampaignPage.tsx";
import { campaignService } from "../services/campaign/campaignService.tsx";
import { bookingRequestService } from "../services/bookingRequest/bookingRequestService.ts";
import SollicitationPage from "../views/public/campaign/SollicitationPage.tsx";
import ExtRequestClosurePage from "../views/public/closure/ExtRequestClosurePage.tsx";
import BookingRequestNotFoundPage from "../views/solicitation/bookingRequest/BookingRequestNotFoundPage.tsx";
import LegalGuidePage from "../views/public/LegalGuidePage.tsx";
import HowItWorksPage from "../views/public/HowItWorksPage.tsx";
import CodeOfConductPage from "../views/public/CodeOfConductPage.tsx";
// import FaqPage from "../views/public/FaqPage.tsx";
import HowToWriteCampaignContextPage from "../views/public/campaign/HowToWriteCampaignContextPage.tsx";
import PlansPage from "../views/public/pro/PlansPage.tsx";
import PlanSubscriptionPage from "../views/public/pro/PlanSubscription/PlanSubscriptionPage.tsx";
import PrivacyPage from "../views/public/PrivacyPage.tsx";
import ConformityGuidePage from "../views/public/ConformityGuidePage.tsx";
import LegalMentionsData from "../views/public/publicContentPages/legalMentionsData.json";
import GeneralConditionsData from "../views/public/publicContentPages/GeneralConditionsData.json";
import PublicContentPage from "../views/public/publicContentPages/publicContentPage.tsx";
import { bookmarkService } from "../services/bookmark/bookmarkService.tsx";
import ProbonoListPage from "../views/profilesList/probonoList/ProbonoListPage.tsx";
import ProbonoLandingPage from "../views/profilesList/probonoList/ProbonoLandingPage.tsx";

const { getLoggedUser } = userService();
const { isLoggedIn } = authService();
const { getCurrentUserBookingProfile, getBookingProfileBySlugAndUsername } =
  bookingProfileService();
const { getPublicCampaign } = campaignService();
const { getPublicBookingRequestByUuid } = bookingRequestService();
const { getDeclineReasons, getPublicAssociations, getPlans } = dataService();
const { getAllBookmarks } = bookmarkService();

export const routes: RouteObject[] = redirectAdapter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <SiteLayout className="--landing" />,
        loader: () => {
          return promiseAllObject({
            loggedUser: getLoggedUser().catch(() => null),
          });
        },
        children: [{ index: true, element: <HomePage /> }],
      },
      {
        id: "site",
        element: <SiteLayout className="--sticky-header --public" />,
        loader: () => {
          return promiseAllObject({
            loggedUser: getLoggedUser().catch(() => null),
          });
        },
        children: [
          {
            path: "/contact",
            loader() {
              return promiseAllObject({
                user: getLoggedUser().catch(() => Promise.resolve(null)),
              });
            },
            element: <ContactPage />,
          },
          {
            path: "/how-it-works",
            element: <HowItWorksPage />,
          },
          {
            path: "/legal-guide",
            element: <LegalGuidePage />,
          },
          {
            path: "/code-of-conduct",
            element: <CodeOfConductPage />,
          },
          // {
          //   path: "/faq",
          //   element: <FaqPage />,
          // },
          {
            path: "/legal-mentions",
            element: <PublicContentPage data={LegalMentionsData} />,
          },
          {
            path: "/general-conditions-of-use",
            element: <PublicContentPage data={GeneralConditionsData} />,
          },
          {
            path: "/privacy",
            element: <PrivacyPage />,
          },
          {
            path: "/conformity-guide",
            element: <ConformityGuidePage />,
          },
          {
            path: "/help/profile-advice",
            element: <ProfileAdvicePage />,
          },
          {
            path: "/help/campaign-advice",
            element: <HowToWriteCampaignContextPage />,
          },
          {
            path: "profiles-list",
            element: <ProfilesListPage />,
            loader: async ({ request }) => {
              // REVIEW
              // get query params from URL, transform them into a object and pass it to the getBookingProfilesRequest function
              const query = {
                limit: "40",
                offset: "0",
              } as Record<string, string>;
              const url = new URL(request.url);
              Array.from(url.searchParams.entries()).forEach(([key, value]) => {
                if (key === "page") {
                  if (Number(value) < 1) return;
                  const nbElementsPerPage = url.searchParams.get("limit")
                    ? Number(url.searchParams.get("limit"))
                    : Number(query.limit);

                  query.offset = String(
                    (Number(value) - 1) * nbElementsPerPage,
                  );
                } else if (key.toLowerCase().includes("price")) {
                  query[key] = String(Number(value) * 100);
                } else if (key === "ref") {
                  query.origin = value;
                } else query[key] = value;
              });

              if (!query.sort) {
                query.sort = "rating:desc";
              }

              return promiseAllObject({
                bookingProfilesWithCount: getBookingProfilesRequest(query),
                configData: getAllConfigDataRequest(),
                bookmarks: isLoggedIn()
                  ? getAllBookmarks()
                  : Promise.resolve([]),
              }).then(({ bookingProfilesWithCount, configData, bookmarks }) => {
                return {
                  bookingProfiles: bookingProfilesWithCount[0],
                  totalProfilesNb: bookingProfilesWithCount[1],
                  configData: mapConfigDataToAutocompleteOptions(configData),
                  bookmarks,
                };
              });
            },
          },
          {
            path: "probono",
            element: <ProbonoLandingPage />,
          },
          {
            path: "probono-profiles",
            element: <ProbonoListPage />,
            loader: async ({ request }) => {
              // get query params from URL, transform them into a object and pass it to the getBookingProfilesRequest function
              const query = {
                limit: "40",
                offset: "0",
              } as Record<string, string>;
              const url = new URL(request.url);
              Array.from(url.searchParams.entries()).forEach(([key, value]) => {
                if (key === "page") {
                  if (Number(value) < 1) return;
                  const nbElementsPerPage = url.searchParams.get("limit")
                    ? Number(url.searchParams.get("limit"))
                    : Number(query.limit);

                  query.offset = String(
                    (Number(value) - 1) * nbElementsPerPage,
                  );
                } else if (key === "ref") {
                  query.origin = value;
                } else query[key] = value;
              });

              if (!query.sort) {
                query.sort = "rating:desc";
              }

              query.probono = "true";
              query.levels = "3";

              return promiseAllObject({
                bookingProfilesWithCount: getBookingProfilesRequest(query),
                configData: getAllConfigDataRequest(),
                bookmarks: isLoggedIn()
                  ? getAllBookmarks()
                  : Promise.resolve([]),
              }).then(({ bookingProfilesWithCount, configData, bookmarks }) => {
                return {
                  bookingProfiles: bookingProfilesWithCount[0],
                  totalProfilesNb: bookingProfilesWithCount[1],
                  configData: mapConfigDataToAutocompleteOptions(configData),
                  bookmarks,
                };
              });
            },
          },
          {
            path: "c/:communitySlug/:username",
            element: <BookingProfilePage />,
            loader: async ({ params }) => {
              try {
                return promiseAllObject({
                  bookingProfile: getBookingProfileBySlugAndUsername(
                    params.communitySlug!,
                    params.username!,
                  ),
                  currentUserBookingProfile: isLoggedIn()
                    ? getCurrentUserBookingProfile(params.communitySlug!)
                    : Promise.resolve(),
                  communitySlug: Promise.resolve(params.communitySlug),
                  username: Promise.resolve(params.username),
                  currentUser: isLoggedIn()
                    ? getLoggedUser()
                    : Promise.resolve(),
                });
              } catch (err) {
                // TODO: Display an error to the user
                return redirect("/app/dashboard");
              }
            },
          },
          {
            path: "campaigns/:campaignUuid",
            element: <CampaignPage />,
            loader: async ({ params }) => {
              return promiseAllObject({
                currentUser: getLoggedUser().catch(() => undefined),
                campaign: getPublicCampaign(String(params.campaignUuid)),
              });
            },
          },
          {
            path: "ext/:bookingRequestUuid",
            element: <SollicitationPage />,
            loader: async ({ params }) => {
              const bookingRequest = await getPublicBookingRequestByUuid(
                params.bookingRequestUuid,
              );

              return promiseAllObject({
                currentUser: getLoggedUser().catch(() => undefined),
                bookingRequest: Promise.resolve(bookingRequest),
                campaign: Promise.resolve(bookingRequest.campaign),
                declineReasons: getDeclineReasons(),
              });
            },
          },
          {
            path: "ext/:bookingRequestUuid/closure",
            element: <ExtRequestClosurePage />,
            errorElement: <BookingRequestNotFoundPage />,
            async loader({ params }) {
              return promiseAllObject({
                bookingRequest: getPublicBookingRequestByUuid(
                  params.bookingRequestUuid,
                ),
                associations: getPublicAssociations(),
              });
            },
          },
          {
            path: "plans",
            id: "plans",
            element: <PlansPage />,
            async loader() {
              return promiseAllObject({
                plans: getPlans(),
              });
            },
          },
          {
            path: "plans/:planId/subscribe",
            element: <PlanSubscriptionPage />,
            async loader({ params }) {
              return promiseAllObject({
                plan: getPlans().then((plans) => {
                  const plan = plans.find(
                    (p) => p.id === Number(params.planId),
                  );
                  if (!plan)
                    throw new Error("Error status code 404: Plan not found");
                  return plan;
                }),
              });
            },
          },
        ],
      },
      authRoutes,
      privateRoutes,
    ],
  },
  ...(IS_DEV ? [sxUiRoutes] : []),
]);
